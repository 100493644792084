import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, pipe } from 'rxjs';
import {finalize, tap} from 'rxjs/operators';
import { LoaderService } from '../_services/loader.service';
@Injectable({
	providedIn: 'root'
})
export class LoaderInterceptorService implements HttpInterceptor {
	requestCount = 0;
	constructor(private readonly loaderService: LoaderService) { }
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (req.url.indexOf('/status') === -1 && !req.url.includes('/assets/i18')) {
			this.showLoader();
			this.requestCount++;
			this.loaderService.show();
			return next.handle(req).pipe(
				finalize(() => {
					this.requestCount--;
					if (this.requestCount === 0) {
						this.loaderService.hide();
					}
				})
			);
		} else {
			return next.handle(req);
		}
	}
	private onEnd(): void {
		this.hideLoader();
	}
	private showLoader(): void {
		this.loaderService.show();
	}
	private hideLoader(): void {
		this.loaderService.hide();
	}
}
