import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root'
})
export class ToasterBarMessageService {
	handler: any;
	defaultMessage: any;
	message: any;
	constructor(private readonly messageService: MessageService,
				private readonly translate: TranslateService) {
	}

	handleMessageService(message: any) {
		this.messageService.add({
			key: 'cAjax',
			severity: 'error',
			summary: this.translate.instant(message)
		});
	}

	handleError(err) {
		this.message = 'SOMETHING_WENT_WRONG';
		const errorHandlers = {
			'ERR015': 'UNAUTHORIZED_ACCESS',
			'ERR016': 'USER_IS_NOT_AUTHORIZED_TO_PERFORM_THE_REQUESTED_ACTION',
			'ERR019': 'NOT_A_VALID_FORD_PASS_USER',
			'ERR037': 'ADD_CHARGEPOINT_LIMIT_EXCEEDED',
			'ERR038': 'USER_INVITE_LIMIT_EXCEEDED',
			'ERR045': 'SOMETHING_WENT_WRONG'
		};
		const errorCode = err.error.error.errorCode;
		this.handler = errorHandlers[errorCode];
		if (this.handleUndefinedOrNullError(err)) {
			this.handleMessageService(this.message);
		} else {
			this.handleMessageService(this.handler);
		} }

	handleUndefinedOrNullError(err) {
		return (err.error === undefined || err.error.error.errorCode === null || err.error.error.errorCode.indexOf('ERR') === -1);
	}

	handleErrorMessage(errMessage) {
		this.defaultMessage = errMessage.error.messages[0];
		const errorMessageHandlers = {
			'ERR001': 'NOT_AN_OWNER_OF_THE_CHARGEPOINT',
			'ERR002': 'CHARGEPOINT_NOT_FOUND',
			'ERR003': 'INFORMATION_COULD_NOT_BE_UPDATED',
			'ERR004': 'COULD_NOT_ALTER_CHARGEPOINT_STATE',
			'ERR005': 'CHARGEPOINT_VALIDATION_FAILED',
			'ERR006': 'CHARGEPOINT_NOT_REGISTERED',
			'ERR009': 'CHARGEPOINT_RESET_FAILED',
			'ERR010': 'USERROLE_NOT_FOUND',
			'ERR011': 'USER_ALREADY_INVITED',
			'ERR012': 'USER_PROFILE_NOT_FOUND',
			'ERR013': 'COULD_NOT_RETRIEVE_TIMEZONE',
			'ERR014': 'CHARGE_STATION_DERATE_FAILED',
			'ERR017': 'AXIOMATIC_FAILURE',
			'ERR018': 'USER_IMAGE_NOT_FOUND_EXCEPTION',
			'ERR020': 'NOT_AN_OWNER_OR_SHARED_USER',
			'ERR021': 'INVITED_USER_NOT_FOUND',
			'ERR022': 'EMAIL_SENDING_FAILED',
			'ERR023': 'INVALID_ADDRESS',
			'ERR024': 'USER_ALREADY_ADDED',
			'ERR025': 'OWNER_CANNOT_BE_ADDED_AS_A_SHARED_USER',
			'ERR026': 'INVALID_REPORT_TYPE',
			'ERR027': 'CHARGE_DATA_RECORD_NOT_FOUND',
			'ERR028': 'VENDOR_DETAILS_NOT_FOUND',
			'ERRO29': 'REDIS_CONNECTION_FAILED',
			'ERR030': 'EXTERNAL_SERVICE_FAILED',
			'ERR031': 'CHARGE_PROFILE_NOT_FOUND',
			'ERR032': 'CHARGE_POINT_CONNECTOR_NOT_FOUND',
			'ERR033': 'UNABLE_TO_MODIFY_ACCESS_CONTROL',
			'ERR034': 'CHARGE_POINT_DETAILS_NOT_FOUND',
			'ERR035': 'INVALID_ACTION_TYPE',
			'ERR036': 'INCORRECT_STATION_ID_OR_ACCESS_CODE'
		};
		const errorCode = errMessage.error.errorCode;
		this.handler = errorMessageHandlers[errorCode];
		if (this.handler) {
			this.handleMessageService(this.handler);
		} else {
			this.handleMessageService(this.defaultMessage);
		}
	}

	handleSuccess(message) {
		this.messageService.add({key: 'cAjax', severity: 'success', summary: message});
	}
}
